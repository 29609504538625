import { TextContainerConfig } from "../styles/TextContainer.config";
import {
  TextContainerAlign,
  TextContainerSize,
  TextContainerVariant,
} from "../types/TextContainer.constants";
import {
  TTextContainerHtml,
  TTextContainerLineHeight,
  TTextContainerVspace,
} from "../types/TextContainer.types";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import { ThemeElement } from "@boilerplate/themes";
import React from "react";

const textContainerAsHtml: Record<TextContainerVariant, TTextContainerHtml> = {
  [TextContainerVariant.Default]: "div",
  [TextContainerVariant.Article]: "article",
};

export interface TextContainerData {}

export interface TextContainerOptions {
  /**
   * Variants for `TextContainer`. You can extend the variant.
   */
  variant?: TextContainerVariant;
  /**
   * Use to override default Html tag
   */
  as?: TTextContainerHtml;
  /**
   * Use to set the size vertical spacing
   */
  vSpace?: TTextContainerVspace;
  /**
   * Use to set the size line-height
   */
  lineHeight?: TTextContainerLineHeight;
  /**
   * Use to set the size vertical spacing
   */
  align?: TextContainerAlign;
  /**
   * Use to set the size vertical spacing
   */
  theme?: ThemeElement;
  size?: TextContainerSize;
}
export interface TextContainerProps
  extends Omit<HTMLProps<any>, keyof TextContainerData>,
    TextContainerOptions,
    TextContainerData {}

type TextContainerParts = ForwardRefComponent<any, TextContainerProps>;

/**
 *  Component to be applied to any block containing typography elements. It takes care of 1) vertical rhythm and 2) styling inline elements.
 */
export const TextContainer: TextContainerParts = forwardRef<
  TextContainerProps,
  "div"
>((props, ref) => {
  const {
    variant = TextContainerVariant.Default,
    theme,
    lineHeight = 1,
    vSpace,
    align,
    size,
    className,
    children,
    ...rest
  } = props;

  const { vSpaceDefault, lineHeightDefault } = React.useMemo(() => {
    switch (variant) {
      case TextContainerVariant.Article:
        return { vSpaceDefault: vSpace || 1.5, lineHeightDefault: lineHeight };
      default:
        return { vSpaceDefault: vSpace || 2, lineHeightDefault: lineHeight };
    }
  }, [variant, vSpace, lineHeight]);

  const { styles } = useCompConfig(TextContainerConfig, {
    variant,
    css: {
      theme,
      align,
      size,
    },
  });

  const Component = textContainerAsHtml[variant];

  return (
    <Component
      data-theme-element={theme}
      className={cx("text-container", styles.Root, className)}
      style={{
        ["--line-height-multiplier" as string]: lineHeightDefault,
        ["--text-vspace-multiplier" as string]: vSpaceDefault,
      }}
      ref={ref}
      {...rest}
    >
      {children}
    </Component>
  );
});

TextContainer.displayName = `TextContainer`;
