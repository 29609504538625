import {
  TextContainerAlign,
  TextContainerParts,
  TextContainerSize,
} from "../types/TextContainer.constants";
import { TTextContainerConfigProps } from "../types/TextContainer.types";
import { ThemeElement } from "@boilerplate/themes";

export const TextContainerConfig: TTextContainerConfigProps = {
  parts: Object.values(TextContainerParts),
  css: {
    variants: {},
    props: {
      size: {
        [TextContainerSize.Sm]: {
          Root: "max-w-sm",
        },
        [TextContainerSize.Md]: {
          Root: "max-w-md",
        },
        [TextContainerSize.Lg]: {
          Root: "max-w-lg",
        },
      },
      align: {
        [TextContainerAlign.Left]: {
          Root: "text-left",
        },
        [TextContainerAlign.Center]: {
          Root: "text-center",
        },
        [TextContainerAlign.Right]: {
          Root: "text-right",
        },
        [TextContainerAlign.CenterLeft]: {
          Root: "text-center md:text-left",
        },
        [TextContainerAlign.LeftCenter]: {
          Root: "text-left md:text-center",
        },
      },
      theme: {
        [ThemeElement.Invert]: {
          Root: "p-component",
        },
      },
    },
  },
};
