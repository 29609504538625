import {
  ButtonParts,
  ButtonSize,
  ButtonTheme,
  ButtonVariant,
} from "../types/Button.constants";
import { TButtonConfigProps } from "../types/Button.types";
import { Status } from "@boilerplate/themes";

export const ButtonConfig: TButtonConfigProps = {
  parts: Object.values(ButtonParts),
  css: {
    variants: {
      [ButtonVariant.Default]: {
        Root: "btn font-bold transition-colors duration-base ease-in-out",
      },
      [ButtonVariant.Outline]: {
        Root: "btn bg-transparent font-bold transition-colors duration-base ease-in-out",
        props: {
          theme: {
            primary: {
              Root: "border-contrast-low text-contrast-high hover:bg-contrast-high hover:text-white hover:border-contrast-high focus:border-contrast-high  focus:bg-contrast-high focus:text-white",
            },
            accent: {
              Root: "border-accent-lighter text-accent hover:bg-accent hover:text-white hover:border-accent focus:border-accent  focus:bg-accent focus:text-white",
            },
          },
        },
      },
      [ButtonVariant.Reset]: {
        Root: null,
        props: {
          theme: {
            [ButtonTheme.Primary]: {
              Root: null,
            },
            [ButtonTheme.Accent]: {
              Root: null,
            },
          },
          size: {
            [ButtonSize.Wide]: {
              Root: null,
            },
          },
        },
      },
    },
    props: {
      theme: {
        [ButtonTheme.Primary]: {
          Root: "bg-contrast-high text-white hover:bg-transparent hover:text-contrast-high hover:border-accent-lighter focus:bg-transparent focus:text-contrast-high focus:border-accent-lighter",
        },
        [ButtonTheme.Accent]: {
          Root: "bg-accent text-white hover:text-accent hover:bg-transparent hover:border-accent focus:text-accent focus:bg-transparent focus:border-accent",
        },
      },
      size: {
        [ButtonSize.Wide]: {
          Root: "w-full",
        },
      },
      state: {
        [Status.Disable]: {
          Root: "opacity-60 cursor-not-allowed",
        },
      },
    },
  },
};
