import { IconConfig } from "../styles/Icon.config";
import { IconName, IconSize, IconVariant } from "../types/Icon.constants";
import { IconsMap } from "../types/Icon.types";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import React from "react";

export interface IconData {
  /**
   * The title of the section as a header
   */
  title?: string;
  /**
   * Need aria-labelledby pointing to ID's of title
   */
  titleId?: string;
}

export interface IconOptions {
  isFilled?: boolean;
  /**
   * Variants for `Icon`. You can extend the variant.
   */
  variant?: IconVariant;
  /**
   * Render the name of Icon component
   */
  name: IconName;
  /**
   * Used to set icon size
   */
  size?: IconSize;
}
export interface IconProps
  extends Omit<HTMLProps<"svg">, keyof IconOptions>,
    IconOptions,
    IconData {}

type IconParts = ForwardRefComponent<"svg", IconProps>;

export const Icon: IconParts = forwardRef<IconProps, "svg">((props, ref) => {
  const {
    variant = IconVariant.Default,
    name = IconName.Hybrbase,
    size = IconSize.Lg,
    isFilled = true,
    className,
    children,
    title = name,
    titleId,
    ...rest
  } = props;

  const { styles } = useCompConfig(IconConfig, { variant, css: { size } });

  const IconLoaderComponent = IconsMap[name];
  if (!IconLoaderComponent) {
    return null;
  }

  return (
    <IconLoaderComponent
      className={cx(styles.Root, { "fill-current": isFilled }, className)}
      ref={ref}
      name={name}
      title={title}
      titleid={titleId}
      {...rest}
    >
      <span className="sr-only">{title}</span>
      {children}
    </IconLoaderComponent>
  );
});

Icon.displayName = `Icon`;
