export enum SectionParts {
  Root = "Root",
}

export enum SectionVariant {
  Default = "default",
}

export enum SectionYSpace {
  Default = "default",
  Small = "small",
  None = "none",
}
