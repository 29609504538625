import {
  SectionParts,
  SectionVariant,
  SectionYSpace,
} from "../types/Section.constants";
import { TSectionConfigProps } from "../types/Section.types";

export const SectionConfig: TSectionConfigProps = {
  parts: Object.values(SectionParts),
  css: {
    variants: {
      [SectionVariant.Default]: {
        Root: "relative overflow-hidden w-full ml-auto mr-auto",
      },
    },
    props: {
      ySpace: {
        [SectionYSpace.Default]: {
          Root: "pt-fluid-xxl pb-fluid-xxl",
        },
        [SectionYSpace.Small]: {
          Root: "pt-fluid-xl pb-fluid-xl",
        },
        [SectionYSpace.None]: {
          Root: null,
        },
      },
    },
  },
};
