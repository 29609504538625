import { IconParts, IconSize, IconVariant } from "../types/Icon.constants";
import { TIconConfigProps } from "../types/Icon.types";

export const IconConfig: TIconConfigProps = {
  parts: Object.values(IconParts),
  css: {
    variants: {
      [IconVariant.Default]: {
        Root: "inline-block text-inherit leading-1 flex-shrink-0 max-w-initial",
      },
    },
    props: {
      size: {
        [IconSize.Auto]: {
          Root: "w-full h-auto",
        },
        [IconSize.Xxxxl]: {
          Root: "w-xxxxl h-xxxxl",
        },
        [IconSize.Xxxl]: {
          Root: "w-xxxxl h-xxxl",
        },
        [IconSize.Xxl]: {
          Root: "w-xxl h-xxl",
        },
        [IconSize.Xl]: {
          Root: "w-xl h-xl",
        },
        [IconSize.Lg]: {
          Root: "w-lg h-lg",
        },
        [IconSize.Md]: {
          Root: "w-md h-md",
        },
        [IconSize.Sm]: {
          Root: "w-sm h-sm",
        },
        [IconSize.Xs]: {
          Root: "w-xs h-xs",
        },
        [IconSize.Xxs]: {
          Root: "w-xxs h-xxs",
        },
        [IconSize.Xxxs]: {
          Root: "w-xxxs h-xxxs",
        },
        [IconSize.Xxxxs]: {
          Root: "w-xxxxs h-xxxxs",
        },
      },
    },
  },
};
