import { ButtonConfig } from "../styles/Button.styles";
import {
  ButtonSize,
  ButtonTheme,
  ButtonVariant,
} from "../types/Button.constants";
import { ButtonLink } from "./ButtonLink";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import { Status } from "@boilerplate/themes";
import React from "react";
import { Icon, IconName, IconSize } from "../../../icon";

export interface ButtonData {
  /**
   * The text of the title attribute is displayed as a tooltip in most browsers
   */
  title: string;
  ariaControls?: string;
  ariaLabel?: string;
  ariaExpanded?: string;
}
export interface ButtonOptions {
  variant?: ButtonVariant;
  state?: Status;
  theme?: ButtonTheme;
  size?: ButtonSize;
  iconName?: IconName;
}

export interface ButtonProps
  extends Omit<HTMLProps<"button">, keyof ButtonData>,
    ButtonOptions,
    ButtonData {}

type ButtonParts = ForwardRefComponent<"button", ButtonProps> & {
  Link?: typeof ButtonLink;
};

/**
 * The Button component is used to trigger an action, open a new page or event, such as submitting a form, opening a dialog, canceling an action, or performing a delete operation.
 */
export const Button: ButtonParts = forwardRef<ButtonProps, "button">(
  (props, ref) => {
    const {
      className,
      children,
      variant = ButtonVariant.Default,
      theme = ButtonTheme.Primary,
      size,
      state,
      title,
      type = "submit",
      role = "button",
      iconName,
      ariaLabel,
      ariaControls,
      ariaExpanded,
      ...rest
    } = props;

    const { styles } = useCompConfig(ButtonConfig, {
      variant,
      css: {
        theme,
        size,
        state,
      },
    });

    return (
      <button
        className={cx(styles.Root, className)}
        ref={ref}
        title={title}
        aria-controls={ariaControls}
        aria-label={ariaLabel}
        aria-expanded={ariaExpanded}
        type={type}
        role={role}
        disabled={state === Status.Disable}
        {...rest}
      >
        {children}
        {iconName && (
          <Icon
            className="!w-[1.25em] ml-xs"
            name={iconName}
            size={IconSize.Auto}
            isFilled={false}
          />
        )}
      </button>
    );
  }
);

Button.Link = ButtonLink;

Button.displayName = `Button`;
Button.Link.displayName = `Button.Link`;
