import {
  ContainerParts,
  ContainerSize,
  ContainerVariant,
} from "../types/Container.constants";
import { TContainerConfigProps } from "../types/Container.types";

export const ContainerConfig: TContainerConfigProps = {
  parts: Object.values(ContainerParts),
  css: {
    variants: {
      [ContainerVariant.Default]: {
        Root: "relative ml-auto mr-auto w-[calc(100%_-_2_*_theme(space.lg))]",
      },
    },
    props: {
      size: {
        [ContainerSize.Sm]: {
          Root: "max-w-sm",
        },
        [ContainerSize.Md]: {
          Root: "max-w-sm sm:max-w-md",
        },
        [ContainerSize.Lg]: {
          Root: "max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl",
        },
      },
    },
  },
};
