export enum IconParts {
  Root = "Root",
}

export enum IconVariant {
  Default = "default",
}

export enum IconSize {
  Auto = "auto",
  Xxxxl = "xxxxl",
  Xxxl = "xxxl",
  Xxl = "xxl",
  Xl = "xl",
  Lg = "lg",
  Md = "md",
  Sm = "sm",
  Xs = "xs",
  Xxs = "xxs",
  Xxxs = "xxxs",
  Xxxxs = "xxxxs",
}

export enum IconName {
  CaptionsOff = "captions-off",
  CaptionsOn = "captions-on",
  EnterFullscreen = "enter-fullscreen",
  ExitFullscreen = "exit-fullscreen",
  Muted = "muted",
  Unmuted = "unmuted",
  Play = "play",
  Pause = "pause",

  Tiktok = "tiktok",
  Inverted = "inverted",
  InvertedTwo = "inverted-two",
  List = "list",
  Close = "close",
  ONECYCLE = "onecycle",
  Facebook = "facebook",
  Instagram = "instagram",
  Hybrbase = "hybrbase",
  Moon = "moon",
  Sun = "sun",
  ArrowRight = "arrow-right",
}
