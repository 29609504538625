export enum ButtonParts {
  Root = "Root",
}

export enum ButtonVariant {
  Default = "default",
  Outline = "outline",
  Reset = "reset",
}

export enum ButtonSize {
  Wide = "wide",
}

export enum ButtonTheme {
  Primary = "primary",
  Accent = "accent",
}
