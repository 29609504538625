import { LinkParts, LinkVariant } from "../types/Link.constants";
import { TLinkConfigProps } from "../types/Link.types";
import { Status } from "@boilerplate/themes";

export const LinkConfig: TLinkConfigProps = {
  parts: Object.values(LinkParts),
  css: {
    variants: {
      [LinkVariant.Default]: {
        Root: "transition-colors duration-base focus-visible:outline-none focus-visible:text-accent",
      },
      [LinkVariant.Underline]: {
        Root: "underline transition-colors duration-base focus-visible:outline-none focus-visible:text-accent font-primary font-bold",
      },
    },
    props: {
      state: {
        [Status.Disable]: {
          Root: "opacity-75 cursor-not-allowed no-underline",
        },
      },
    },
  },
};
