import { createContext } from "@hybrbase/system";
import { Status } from "@boilerplate/themes";
import { LinkProps as NextLinkProps } from "next/link";
import React from "react";

export interface UseLinkProps extends NextLinkProps {
  /**
   * If `true`, the link will open in new tab
   */
  isOpenNewTab?: boolean;
  onMouseEnter?: React.MouseEventHandler<Element> | undefined;
  onClick?: React.MouseEventHandler;
  state?: Status;
}

/**
 * Link hook that manages all the logic
 * and returns prop getters, state and actions.
 *
 * @param props
 */
export const useLink = (props: UseLinkProps) => {
  const { state, href, isOpenNewTab } = props;

  const isDisabled = state === Status.Disable;
  const htmlTarget = isOpenNewTab ? `_blank` : undefined;
  const htmlRel = isOpenNewTab ? `noopener noreferrer` : undefined;

  const isExternalLink = (href = ""): boolean => {
    const protocol = href && href.split(":").shift();
    return (
      protocol === "https" ||
      protocol === "http" ||
      protocol === "mailto" ||
      protocol === "tel" ||
      protocol === "www"
    );
  };

  return {
    htmlTarget,
    htmlRel,
    isDisabled,
    isExternalLink: href && isExternalLink(href.toString()),
    role: "link",
    tabIndex: isDisabled ? -1 : 0,
    state: isDisabled ? Status.Disable : state,
  };
};

export type UseLinkReturn = ReturnType<typeof useLink>;

const [LinkProvider, useLinkContext] = createContext<UseLinkReturn>({
  name: "LinkContext",
  errorMessage:
    "useLinkContext: `context` is undefined. Seems you forgot to wrap all Link's components within <Link />",
});

export { LinkProvider, useLinkContext };
