import { Link, useLink, LinkOptions } from "../../../link";
import { ButtonConfig } from "../styles/Button.styles";
import { ButtonTheme, ButtonVariant } from "../types/Button.constants";
import { ButtonOptions } from "./Button";
import { cx, forwardRef, HTMLProps, useCompConfig } from "@hybrbase/system";
import React from "react";
import { Icon, IconSize } from "../../../icon";

/**
 * ButtonLink
 */
export interface ButtonLinkProps
  extends Omit<HTMLProps<"a">, keyof LinkOptions>,
    ButtonOptions,
    LinkOptions {}

export const ButtonLink = forwardRef<ButtonLinkProps, "a">(
  (
    {
      className,
      children,
      variant = ButtonVariant.Default,
      theme = ButtonTheme.Primary,
      size,
      state,
      title,
      href,
      isOpenNewTab,
      locale,
      iconName,
      ...rest
    },
    ref
  ) => {
    const { ...ctx } = useLink({ state, href, isOpenNewTab });

    const { styles } = useCompConfig(ButtonConfig, {
      variant,
      css: {
        theme,
        size,
        state: ctx.state,
      },
    });

    return (
      <Link
        href={href}
        className={cx(styles.Root, className)}
        ref={ref}
        title={title}
        target={ctx.htmlTarget}
        rel={ctx.htmlRel}
        locale={locale}
        {...rest}
      >
        {children}
        {iconName && (
          <Icon
            className="!w-[1.25em] ml-xs"
            name={iconName}
            size={IconSize.Auto}
            isFilled={false}
          />
        )}
      </Link>
    );
  }
);
