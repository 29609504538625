import { createContext } from "@hybrbase/system";

export interface UseSectionProps {}

/**
 * Section hook that manages all the logic
 * and returns prop getters, state and actions.
 *
 * @param props
 */
export const useSection = (props: UseSectionProps) => {
  const {} = props;
  return {};
};

export type UseSectionReturn = ReturnType<typeof useSection>;

const [SectionProvider, useSectionContext] = createContext<UseSectionReturn>({
  name: "SectionContext",
  errorMessage:
    "useSectionContext: `context` is undefined. Seems you forgot to wrap all Section's components within <Section />",
});

export { SectionProvider, useSectionContext };
