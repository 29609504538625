export enum TextContainerParts {
  Root = "Root",
}

export enum TextContainerVariant {
  Default = "default",
  Article = "article",
}

export enum TextContainerSize {
  Sm = "sm",
  Md = "md",
  Lg = "lg",
}

export enum TextContainerAlign {
  Left = `left`,
  Center = `center`,
  Right = `right`,
  LeftCenter = `left-center`,
  CenterLeft = `center-left`,
}
