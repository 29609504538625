import { LinkConfig } from "../styles/Link.config";
import { LinkVariant } from "../types/Link.constants";
import { useLink, UseLinkProps } from "../utils/use-link";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import NextLink from "next/link";
import React from "react";

export interface LinkData {}

export interface LinkOptions extends UseLinkProps {
  locale?: string | false;
}

export interface LinkProps
  extends Omit<HTMLProps<"a">, keyof UseLinkProps>,
    LinkOptions,
    LinkData {
  variant?: LinkVariant;
}

type LinkParts = ForwardRefComponent<"a", LinkProps>;

/**
 * Link are accessible elements used primarily for navigation
 */
export const Link: LinkParts = forwardRef<LinkProps, "a">((props, ref) => {
  const {
    variant = LinkVariant.Default,
    state,
    isOpenNewTab = false,
    className,
    children,
    title,
    href = "/",
    as,
    prefetch,
    locale,
    ...rest
  } = props;

  const { ...ctx } = useLink({ state, href, isOpenNewTab });

  const { styles } = useCompConfig(LinkConfig, {
    variant,
    css: {
      state: ctx.state,
    },
  });

  const sharedProps = {
    title,
    target: ctx.htmlTarget,
    rel: ctx.htmlRel,
    className: cx(styles.Root, className),
    ref,
    role: "link",
    tabIndex: ctx.isDisabled ? -1 : 0,
  };

  if (!ctx.isExternalLink) {
    const assureSlash = href?.toString().startsWith("/");

    return (
      <NextLink
        href={assureSlash ? href : `/${href}`}
        as={as}
        prefetch={prefetch}
        locale={locale}
      >
        <a {...sharedProps} {...rest}>
          {children}
        </a>
      </NextLink>
    );
  }

  return (
    <NextLink href={href || "/"} passHref>
      <a {...sharedProps} {...rest}>
        {children}
      </a>
    </NextLink>
  );
});

Link.displayName = "Link";
