import { IconName, IconParts, IconSize, IconVariant } from "./Icon.constants";
import dynamic from "next/dynamic";

export const IconsMap: Record<IconName, any> = {
  [IconName.CaptionsOn]: dynamic(() => import("../loader/CaptionsOn")),
  [IconName.CaptionsOff]: dynamic(() => import("../loader/CaptionsOff")),
  [IconName.EnterFullscreen]: dynamic(
    () => import("../loader/EnterFullscreen")
  ),
  [IconName.ExitFullscreen]: dynamic(() => import("../loader/ExitFullscreen")),
  [IconName.Muted]: dynamic(() => import("../loader/Muted")),
  [IconName.Unmuted]: dynamic(() => import("../loader/Unmuted")),
  [IconName.Play]: dynamic(() => import("../loader/Play")),
  [IconName.Pause]: dynamic(() => import("../loader/Pause")),

  [IconName.Tiktok]: dynamic(() => import("../loader/Tiktok")),
  [IconName.Inverted]: dynamic(() => import("../loader/Inverted")),
  [IconName.InvertedTwo]: dynamic(() => import("../loader/InvertedTwo")),
  [IconName.Close]: dynamic(() => import("../loader/Close")),
  [IconName.List]: dynamic(() => import("../loader/List")),
  [IconName.ONECYCLE]: dynamic(() => import("../loader/ONECYCLE")),
  [IconName.Instagram]: dynamic(() => import("../loader/Instagram")),
  [IconName.Facebook]: dynamic(() => import("../loader/Facebook")),
  [IconName.Hybrbase]: dynamic(() => import("../loader/Hybrbase")),
  [IconName.Sun]: dynamic(() => import("../loader/Sun")),
  [IconName.Moon]: dynamic(() => import("../loader/Moon")),
  [IconName.ArrowRight]: dynamic(() => import("../loader/ArrowRight")),
};

export type TIconParts = {
  [part in IconParts]?: string;
};

export type TIconCssProps = {
  size: {
    [size in IconSize]?: TIconParts;
  };
};

export interface TIconConfigProps {
  parts: IconParts[];
  css: {
    variants: {
      [variant in IconVariant]?: TIconParts & { props?: TIconCssProps };
    };
    props?: TIconCssProps;
  };
}
