import { SectionConfig } from "../styles/Section.config";
import { SectionVariant, SectionYSpace } from "../types/Section.constants";
import {
  SectionProvider,
  useSection,
  UseSectionProps,
} from "../utils/use-section";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import { ThemeElement } from "@boilerplate/themes";
import React from "react";

// import { ThemeElement } from "@boilerplate/themes";

export interface SectionData {
  /**
   * The subtitle of the section
   */
  subtitle?: string;
  /**
   * The title of the section as a header
   */
  title: string;
  /**
   * The text of the section to divided into paragraphs
   */
  text?: string;
}

export interface SectionOptions {
  /**
   * Variants for `Section`. You can extend the variant.
   */
  variant?: SectionVariant;

  theme?: ThemeElement;

  ySpace?: SectionYSpace;
}
export interface SectionProps
  extends Omit<HTMLProps<"section">, keyof SectionData>,
    SectionOptions,
    UseSectionProps {}

type SectionParts = ForwardRefComponent<"section", SectionProps>;

/**
 * Section - The Section is a wrapper element to group components, and can be used to give space between other section.
 */
export const Section: SectionParts = forwardRef<SectionProps, "section">(
  (props, ref) => {
    const {
      variant = SectionVariant.Default,
      theme,
      ySpace = SectionYSpace.Default,
      className,
      children,
      ...rest
    } = props;
    const { ...ctx } = useSection(rest);
    const context = React.useMemo(() => ctx, [ctx]);

    const { styles } = useCompConfig(SectionConfig, {
      variant,
      css: { ySpace },
    });

    return (
      <SectionProvider value={context}>
        <section
          data-theme-element={theme}
          className={cx(styles.Root, "sm:mh-[450px]", className)}
          ref={ref}
          {...rest}
        >
          {children}
        </section>
      </SectionProvider>
    );
  }
);

Section.displayName = `Section`;
