import { ContainerConfig } from "../styles/Container.config";
import { ContainerSize, ContainerVariant } from "../types/Container.constants";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import React from "react";

export interface ContainerData {}

export interface ContainerOptions {
  /**
   * Variants for `Container`. You can extend the variant.
   */
  variant?: ContainerVariant;
  size?: ContainerSize;
}
export interface ContainerProps
  extends Omit<HTMLProps<"div">, keyof ContainerData>,
    ContainerOptions,
    ContainerData {}

type ContainerParts = ForwardRefComponent<"div", ContainerProps>;

/**
 * Container - Containers are used to constrain a content's width to the current breakpoint, while keeping it fluid.
 */
export const Container: ContainerParts = forwardRef<ContainerProps, "div">(
  (props, ref) => {
    const {
      variant = ContainerVariant.Default,
      size = ContainerSize.Lg,
      className,
      children,
      ...rest
    } = props;

    const { styles } = useCompConfig(ContainerConfig, {
      variant,
      css: { size },
    });

    return (
      <div className={cx(styles.Root, className)} ref={ref} {...rest}>
        {children}
      </div>
    );
  }
);

Container.displayName = `Container`;
